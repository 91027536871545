export const routes = [
    {
      path: '/email/notify',
      name: 'emailNotify',
      component: () => import(/* webpackChunkName: "emailNotify" */ '@/views/auth/EmailNotify.vue'),
      meta: { guard: 'unverified' }
    },
    {
      path: '/email/verify/:id/:hash',
      name: 'emailVerify',
      component: () => import(/* webpackChunkName: "emailVerify" */ '@/views/auth/EmailVerify.vue'),
      meta: { guard: 'unverified' }
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: () => import(/* webpackChunkName: "forgotPassword" */ '@/views/auth/ForgotPassword.vue'),
      meta: { guard: 'guest' }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
      meta: { guard: 'guest' }
    },
    {
      path: '/Logout',
      name: 'logout',
      component: () => import(/* webpackChunkName: "logout" */ '@/views/auth/Logout.vue'),
      meta: { guard: 'authenticated' }
    },
    {
      path: '/register',
      name: 'register',
      component: () => import(/* webpackChunkName: "register" */ '@/views/auth/Register.vue'),
      meta: { guard: 'guest' }
    },
    {
      path: '/reset-password/:token',
      name: 'resetPassword',
      component: () => import(/* webpackChunkName: "resetPassword" */ '@/views/auth/ResetPassword.vue'),
      meta: { guard: 'guest' }
    },
    {
      path: '/account',
      name: 'account',
      component: () => import(/* webpackChunkName: "account" */ '@/views/auth/Account.vue'),
      meta: { guard: 'authenticated' }
    },
  ]
  